<template>
  <div>
    <div class="font-weight-bold">Итог:</div>
    <div class="mb-4">{{ states[state].stateText }}</div>
    <v-btn text outlined :loading="isLoading" @click="onClick">
      {{ states[state].buttonText }}
    </v-btn>
  </div>
</template>

<script>
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'

import { changeIsAdminPass } from '@/lib/requestsAPI'

export default {
  props: ['isAdminPass', 'isAutoPass', 'isForced', 'id'],
  mixins: [sendDataWithToastNoRedirect],
  data() {
    return {
      isLoading: false,
      autoPassOk: 'autoPassOk',
      stopByAdmin: 'stopByAdmin',
      autoStopOk: 'autoStopOk',
      passByAdmin: 'passByAdmin',
      forced: 'forced',
      forcedCancel: 'forcedCancel',
      states: {
        forced: {
          stateText: 'ГВС не отвечал. Критерий пройдет',
          buttonText: 'Не пропускать',
          buttonActionValue: false,
          successMessage: 'Теперь критерий не пройдет',
        },
        forcedCancel: {
          stateText: 'ГВС не отвечал. Вы решили не пропускать этот критерий',
          buttonText: 'Вернуть в работу',
          buttonActionValue: null,
          successMessage: 'Теперь критерий пройдет',
        },
        autoPassOk: {
          stateText: 'Критерий проходит по ответам ГВС',
          buttonText: 'Не пропускать',
          buttonActionValue: false,
          successMessage: 'Теперь критерий не пройдет',
        },
        stopByAdmin: {
          stateText:
            'Критерий проходит по ответам ГВС, но вы решили не отбирать его для дальнейшей работы',
          buttonText: 'Вернуть в работу',
          buttonActionValue: null,
          successMessage: 'Теперь критерий пройдет',
        },
        autoStopOk: {
          stateText: 'Критерий не проходит по ответам ГВС',
          buttonText: 'Все равно отобрать',
          buttonActionValue: true,
          successMessage: 'Теперь критерий пройдет',
        },
        passByAdmin: {
          stateText:
            'Критерий не проходит по ответам ГВС, но вы отобрали его для дальнейшей работы',
          buttonText: 'Отменить выбор',
          buttonActionValue: null,
          successMessage: 'Теперь критерий не пройдет',
        },
      },
    }
  },
  computed: {
    state() {
      if (this.isForced) {
        return this.isAdminPass === null ? this.forced : this.forcedCancel
      }

      if (this.isAdminPass === null) {
        return this.isAutoPass ? this.autoPassOk : this.autoStopOk
      }

      if (this.isAutoPass) {
        return this.isAdminPass ? this.autoPassOk : this.stopByAdmin
      } else {
        return this.isAdminPass ? this.passByAdmin : this.autoStopOk
      }
    },
  },
  methods: {
    onClick() {
      if (this.isLoading) return
      this.isLoading = true
      const currentState = this.states[this.state]
      this.sendDataWithToastNoRedirect({
        action: changeIsAdminPass,
        onSuccess: this.onSuccess,
        onFinally: this.onFinally,
        data: { id: this.id, value: currentState.buttonActionValue },
        successMessage: currentState.successMessage,
        errorMessage: `Не удалось изменить состояние для критерия ${this.id}`,
      })
    },
    onSuccess() {
      this.$emit('update')
    },
    onFinally() {
      this.isLoading = false
    },
  },
}
</script>
