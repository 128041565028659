<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <SelectCriteriaPass
        :projectNosologia="projectNosologia"
        @update="update"
      />
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import SelectCriteriaPass from '@/components/project/stages/gvsFinish/SelectCriteriaPass.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

// import { API_PROJECTS } from '@/store/const/apiPaths'
import { API_PROJECTS_NOSOLOGIA_IDENTITY } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю проект',
    }
  },
  components: {
    DisplayLoading,
    SelectCriteriaPass,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROJECTS_NOSOLOGIA_IDENTITY}?project_id=${this.projectId}&nosology_id=${this.nosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить ответы для проекта: ${this.projectId} и заболевания: ${this.nosologiaId}`
    },
    projectNosologia() {
      return this.pageData
    },
  },
}
</script>
