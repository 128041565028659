<template>
  <div class="card not-pass" :class="{ pass: isPass }">
    <!-- title -->
    <div class="mb-4">
      <div class="font-weight-bold mb-1">{{ answers.name }}</div>
      <div class="grey--text">{{ answers.name203n }}</div>
    </div>

    <!-- if Forced -->
    <div v-if="answers.isForced" class="mb-4">
      <div class="mb-1 font-weight-bold">Ответы и комментарии:</div>
      <div class="grey--text">
        <v-icon>mdi-cancel</v-icon>
        <span>ГВС не отвечал на критерий</span>
      </div>
    </div>

    <!-- Regular answers -->
    <div v-else class="mb-4">
      <!-- answers -->
      <div class="mb-1 font-weight-bold">Ответы:</div>
      <div class="d-flex mb-2">
        <v-chip outlined large class="mr-2" v-if="answers.isStationar"
          >Специализированная</v-chip
        >
        <v-chip outlined large class="mr-2" v-if="answers.isAmbulatory"
          >Первичная</v-chip
        >
        <v-chip outlined large class="mr-2" v-if="answers.readableStatus">{{
          answers.readableStatus
        }}</v-chip>
        <v-chip outlined large class="mr-2" v-if="answers.criteriaNecessity"
          >Необходимость: {{ answers.criteriaNecessity }}</v-chip
        >
      </div>

      <!-- comments -->
      <div class="mb-4">
        <div v-if="hasAnyComment">
          <div class="mb-1">
            <span class="tip" @click="switchComment">{{ buttonText }}</span>
          </div>
          <div v-if="showComment">
            <TitleAndDataLine
              v-if="typeComment"
              label="Вид помощи"
              :value="typeComment"
            />

            <TitleAndDataLine
              v-if="statusComment"
              label="Общий или этап"
              :value="statusComment"
            />
            <TitleAndDataLine
              v-if="necessityComment"
              label="Необходимость"
              :value="necessityComment"
            />
          </div>
        </div>
        <div v-else class="grey--text">Комментариев нет</div>
      </div>
    </div>

    <!-- isPass -->
    <AdminPassBlock
      class=""
      :isAdminPass="answers.isAdminPass"
      :isAutoPass="answers.isAutoPass"
      :isForced="answers.isForced"
      :id="answers.id"
      @update="throwUpdate"
    />
  </div>
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import AdminPassBlock from '@/components/project/stages/gvsFinish/AdminPassBlock.vue'

import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'

export default {
  props: ['rawOneCriteriaAnswers'],
  components: { TitleAndDataLine, AdminPassBlock },
  data() {
    return {
      showComment: false,
    }
  },
  methods: {
    switchComment() {
      this.showComment = !this.showComment
    },
    throwUpdate() {
      this.$emit('update')
    },
  },
  computed: {
    isPass() {
      return this.answers.isAdminPass === null
        ? this.answers.isAutoPass
        : this.answers.isAdminPass
    },
    necessityComment() {
      return this.rawOneCriteriaAnswers.necessityComment
    },
    statusComment() {
      return this.rawOneCriteriaAnswers.statusComment
    },
    typeComment() {
      return this.rawOneCriteriaAnswers.typeComment
    },
    hasAnyComment() {
      const { necessityComment, statusComment, typeComment } = this
      return necessityComment || statusComment || typeComment
    },
    hasExactlyOneComment() {
      const { necessityComment, statusComment, typeComment } = this
      return (
        Boolean(necessityComment) +
          Boolean(statusComment) +
          Boolean(typeComment) ===
        1
      )
    },
    answers() {
      if (this.rawOneCriteriaAnswers.isForced) return this.rawOneCriteriaAnswers
      return {
        ...this.rawOneCriteriaAnswers,
        readableStatus:
          CRITERIA_STATUS[this.rawOneCriteriaAnswers.criteriaStatus].name || '',
      }
    },
    buttonText() {
      if (this.showComment) return 'Свернуть'
      return this.hasExactlyOneComment
        ? 'Показать комментарий'
        : 'Показать комментарии'
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
.card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.not-pass {
  border-color: rgba(128, 0, 0, 0.5);
  box-shadow: 0px 3px 1px -2px rgb(128 0 0 / 20%),
    0px 2px 2px 0px rgb(128 0 0 / 14%), 0px 1px 5px 0px rgb(128 0 0 / 12%);
}
.pass {
  border-color: rgba(0, 128, 0, 0.5);
  box-shadow: 0px 3px 1px -2px rgb(0 128 0 / 20%),
    0px 2px 2px 0px rgb(0 128 0 / 14%), 0px 1px 5px 0px rgb(0 128 0 / 12%);
}
</style>
