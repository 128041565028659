<template>
  <div class="card not-pass" :class="{ pass: isPass }">
    <!-- title -->
    <div class="mb-4">
      <div class="font-weight-bold mb-1">{{ answers.name }}</div>
      <div class="grey--text mb-1">{{ answers.name203n }}</div>
    </div>

    <!-- if Forced -->
    <div v-if="isAllForced" class="mb-4">
      <div class="mb-1 font-weight-bold">Ответы и комментарии:</div>
      <div class="grey--text">
        <v-icon>mdi-cancel</v-icon>
        <span>Ни один ГВС не ответил на критерий</span>
      </div>
    </div>

    <!-- Regular answers -->
    <div v-else class="mb-4">
      <!-- answers -->
      <div class="mb-1 font-weight-bold">Ответы:</div>
      <div class="d-flex flex-wrap mb-2">
        <v-chip outlined large class="ma-1" v-if="answers.isStationar"
          >Специализированная: {{ answers.isStationar }} из {{ answers.total }}
        </v-chip>

        <v-chip outlined large class="ma-1" v-if="answers.isAmbulatory"
          >Первичная: {{ answers.isAmbulatory }} из {{ answers.total }}
        </v-chip>

        <v-chip
          outlined
          large
          class="ma-1"
          v-for="status in criteriaStatuses"
          :key="status.name"
        >
          {{ status.label }}: {{ status.value }} из {{ answers.total }}
        </v-chip>

        <v-chip outlined large class="ma-1"
          >Необходимости: {{ answers.readableAllNecessity }}</v-chip
        >
      </div>

      <!-- comments -->
      <div class="mb-4">
        <div v-if="numberOfComments">
          <div class="mb-1">
            <span class="tip" @click="switchComment">{{ buttonText }}</span>
          </div>
          <div v-if="showComment">
            <v-alert dense outlined text type="info" class="mb-4">
              Отвечали несколько ГВС — показываю все комментарии без уточнения
              вопроса и автора</v-alert
            >
            <div
              v-for="(comment, index) in answers.allComments"
              :key="index"
              class="mb-2"
            >
              {{ comment }}
            </div>
          </div>
        </div>
        <div v-else class="grey--text">Комментариев нет</div>
      </div>
    </div>

    <!-- isPass -->
    <AdminPassBlock
      class=""
      :isAdminPass="answers.isAdminPass"
      :isAutoPass="answers.isAutoPass"
      :id="answers.id"
      :isForced="isAllForced"
      @update="throwUpdate"
    />
  </div>
</template>

<script>
import AdminPassBlock from '@/components/project/stages/gvsFinish/AdminPassBlock.vue'
import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'

export default {
  props: ['rawOneCriteriaAnswers'],
  components: { AdminPassBlock },
  data() {
    return {
      showComment: false,
    }
  },
  methods: {
    switchComment() {
      this.showComment = !this.showComment
    },
    throwUpdate() {
      this.$emit('update')
    },
  },
  computed: {
    isAllForced() {
      return this.answers.isAmbulatory + this.answers.isStationar === 0
    },
    isPass() {
      return this.answers.isAdminPass === null
        ? this.answers.isAutoPass
        : this.answers.isAdminPass
    },
    criteriaStatuses() {
      return Object.values(CRITERIA_STATUS)
        .map(v => ({
          label: v.name,
          value: this.answers[v.nameInMultiAnswers],
        }))
        .filter(st => st.value)
    },
    answers() {
      if (this.rawOneCriteriaAnswers.isForced) return this.rawOneCriteriaAnswers
      return {
        ...this.rawOneCriteriaAnswers,
        readableAllNecessity:
          this.rawOneCriteriaAnswers.criteriaNecessity.join(', '),
      }
    },
    numberOfComments() {
      return this.answers.allComments.length
    },
    buttonText() {
      if (this.showComment) return 'Свернуть'
      return this.numberOfComments === 1
        ? 'Показать комментарий'
        : 'Показать комментарии'
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
.card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.not-pass {
  border-color: rgba(128, 0, 0, 0.5);
  box-shadow: 0px 3px 1px -2px rgb(128 0 0 / 20%),
    0px 2px 2px 0px rgb(128 0 0 / 14%), 0px 1px 5px 0px rgb(128 0 0 / 12%);
}
.pass {
  border-color: rgba(0, 128, 0, 0.5);
  box-shadow: 0px 3px 1px -2px rgb(0 128 0 / 20%),
    0px 2px 2px 0px rgb(0 128 0 / 14%), 0px 1px 5px 0px rgb(0 128 0 / 12%);
}
</style>
