<template>
  <TitleAndDataLine :label="label" :value="userNames" />
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'

export default {
  props: ['label', 'value'],
  components: {
    TitleAndDataLine,
  },
  computed: {
    userNames() {
      return this.value.map(p => p.fio).join(', ')
    },
  },
}
</script>
