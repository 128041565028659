<template>
  <div>
    <PageHeader text="Проверка критериев для отправки в НМИЦ" />
    <TitleAndDataLine label="Проект" :value="projectName" />
    <TitleAndDataLine label="Нозология" :value="nosologiaName" />
    <TitleAndDataNames label="ГВС" :value="gvsUsers" class="mb-8" />

    <div class="mb-8 d-flex align-center grey--text">
      <v-icon dense class="mr-2">mdi-lightbulb-on-outline</v-icon>
      <div>
        Зеленая рамка означает, что критерий пройдет на этап НМИЦ. Красная рамка
        — не пройдет.
      </div>
    </div>

    <div v-if="moreThenOneGVS" class="mb-8">
      <MultiAnswersWithSelect
        v-for="criteriaAnswers in nosologiaAnswers"
        :key="criteriaAnswers.id"
        class="mb-2 pa-4"
        :rawOneCriteriaAnswers="criteriaAnswers"
        @update="throwUpdate"
      />
    </div>
    <div v-else class="mb-8">
      <AnswersWithSelect
        v-for="criteriaAnswers in nosologiaAnswers"
        :key="criteriaAnswers.id"
        class="mb-2 pa-4"
        :rawOneCriteriaAnswers="criteriaAnswers"
        @update="throwUpdate"
      />
    </div>

    <BackToProjectButton />
  </div>
</template>

<script>
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import TitleAndDataNames from '@/components/common/TitleAndDataNames.vue'
import BackToProjectButton from '@/components/buttons/BackToProjectButton.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import AnswersWithSelect from '@/components/project/stages/gvsFinish/AnswersWithSelect.vue'
import MultiAnswersWithSelect from '@/components/project/stages/gvsFinish/MultiAnswersWithSelect.vue'

import { routeParams } from '@/mixins/routeParams'

import { USER_GVS_ID } from '@/store/const/userRoles'
import { CRITERIA_STATUS } from '@/store/const/criteriaStatus'

export default {
  props: ['projectNosologia'],
  mixins: [routeParams],
  components: {
    PageHeader,
    TitleAndDataLine,
    AnswersWithSelect,
    TitleAndDataNames,
    MultiAnswersWithSelect,
    BackToProjectButton,
  },
  methods: {
    throwUpdate() {
      this.$emit('update')
    },
    dataStacker(to, from) {
      if (!from) return

      to.isAmbulatory += from.isAmbulatory
      to.isStationar += from.isStationar
      to.criteriaNecessity.push(from.criteriaNecessity)

      to[CRITERIA_STATUS[from.criteriaStatus].nameInMultiAnswers] += 1

      const { necessityComment, statusComment, typeComment } = from
      if (necessityComment) to.allComments.push(necessityComment)
      if (statusComment) to.allComments.push(statusComment)
      if (typeComment) to.allComments.push(typeComment)
    },
  },
  computed: {
    // projectNosologia() {
    //   return this.project.projectNosologies.find(
    //     pn => pn.nosology_id === this.nosologiaId
    //   )
    // },
    nosologiaName() {
      return this.projectNosologia.nosology.name
    },
    projectName() {
      return this.projectNosologia.project.name
    },
    userProjectNosologies() {
      return this.projectNosologia.userProjectNosologies
    },
    gvsUsers() {
      return this.userProjectNosologies
        .filter(upn => upn.user.role_id === USER_GVS_ID)
        .map(upn => upn.user)
    },
    moreThenOneGVS() {
      return this.gvsUsers.length > 1
    },
    prepareAnswersForOne() {
      return this.userProjectNosologies
        .find(upn => upn.user.role_id === USER_GVS_ID)
        .userCriterionStages.map(ucs => ({
          ...ucs.data,
          id: ucs.criteria_stage_id,
          isAdminPass: ucs.criterionStage.is_approved,
          isAutoPass: ucs.criterionStage.is_passed,
          isForced: ucs.criterionStage.is_forced,
          name: ucs.criterionStage.criterion.name,
          name203n: ucs.criterionStage.criterion.name_203n,
        }))
    },
    allData() {
      return this.userProjectNosologies
        .filter(upn => upn.user.role_id === USER_GVS_ID)
        .map(upns => upns.userCriterionStages)
    },
    allAnswers() {
      const { allData } = this
      const result = []

      for (let i = 0; i < allData.length; i++) {
        for (let j = 0; j < allData[i].length; j++) {
          if (result.length === j) {
            result.push({
              isStationar: 0,
              isAmbulatory: 0,
              statusCommon: 0,
              statusOnStage: 0,
              criteriaNecessity: [],
              allComments: [],
            })
          }
          this.dataStacker(result[j], allData[i][j].data)
        }
      }
      return result
    },
    prepareAnswersForMany() {
      return this.userProjectNosologies
        .find(upn => upn.user.role_id === USER_GVS_ID)
        .userCriterionStages.map((ucs, index) => ({
          // Количество отвечавших ГВСов. Число вообще одинаковое. Можно отдельным пропсом отдать
          total: this.gvsUsers.length,
          // Это сводные ответы всех ГВСов по критерию
          ...this.allAnswers[index],
          // Эти данные берем с одного ГВСа но они одинаковые у всех ответов внутри критерия
          id: ucs.criteria_stage_id,
          isAdminPass: ucs.criterionStage.is_approved,
          isAutoPass: ucs.criterionStage.is_passed,
          name: ucs.criterionStage.criterion.name,
          name203n: ucs.criterionStage.criterion.name_203n,
        }))
    },
    nosologiaAnswers() {
      return this.moreThenOneGVS
        ? this.prepareAnswersForMany
        : this.prepareAnswersForOne
    },
  },
}
</script>
